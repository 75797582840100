<template>
  <div id="app">
    <div class="main__wrap">

      <nav class="menu">
        <div class="menu-item"><a target="_blank" rel="noopener noreferrer" href="https://medium.com/innovate-unchained">Blog</a></div>
        <div class="menu-item"><a href="mailto:robert@robertmillerconsultancy.com">Contact</a></div>
      </nav>

      <div class="logo">
        <!-- <img src="@/assets/logo.gif" alt="logo"/> -->
        <video class="video" autoplay playsinline loop muted>
          <source src="@/assets/logo-video.mp4" type="video/mp4">
        </video>
      </div>

      <div class="services">
        <div class="service-item left">Consulting</div>
        <div class="service-separator">-</div>
        <div class="service-item center">Strategy</div>
        <div class="service-separator">-</div>
        <div class="service-item right">PR</div>
      </div>

      <footer class="footer">
        <div class="footer-item-social">
          <div class="twitter">
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/GoUnchained">
            <TwitterIcon />
            </a>
          </div>
          <div class="linkedin">
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/innovate-unchained">
            <LinckedinIcon />
            </a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import TwitterIcon from 'vue-material-design-icons/Twitter.vue';
import LinckedinIcon from 'vue-material-design-icons/Linkedin.vue';

export default {
  name: 'App',
  components: {
    TwitterIcon,
    LinckedinIcon,
  },
  mounted() {

  },
};
</script>

<style lang="stylus">
a, a:visited
  color #dedede
  text-decoration none

a:hover
  font-weight bold
  text-decoration underline

#app
  font-family Courier
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  height 100vh

@supports (-webkit-touch-callout: none)
  /* CSS specific to iOS devices */
  #app
    height calc(100vh - 75px)

.main__wrap
  box-sizing border-box
  display flex
  flex-direction column
  background-color #000000
  color #dedede
  min-height 100%

.menu
  box-sizing border-box
  display flex
  justify-content flex-end

.menu-item
  box-sizing border-box
  padding 1rem

.logo
  box-sizing border-box
  flex 1 0 auto
  display flex
  justify-content center
  align-items center
  user-select none

.video
  box-sizing border-box
  // border 1px solid red
  width 100%
  box-sizing border-box
  @media (min-width: 32rem) and (orientation: landscape)
    height 50vh
    width auto

.services
  box-sizing border-box
  // border 1px solid red
  width 80%
  align-self center
  display flex
  flex-direction column
  justify-content space-around
  align-items center
  min-height 50px
  @media (min-width: 32rem) and (orientation: landscape)
    flex-direction row
  @media (min-width: 48rem)
    flex-direction row
    min-height 200px
  @media (min-width: 80rem)
    width 60%

.service-item
  box-sizing border-box
  // border 1px solid brown
  font-size 30px
  font-weight bold
  user-select none
  flex 1
  &.center
    // width 0px
    // flex 1 0 auto
    text-align center
  &.left
    text-align right
  &.right
    text-align left
.service-separator
  box-sizing border-box
  // border 1px solid yellow
  flex 1
  text-align center

.footer
  box-sizing border-box
  display flex
  justify-content flex-start
  align-items flex-end
  @media (min-width: 48rem)
    min-height 100px

.footer-item-social
  box-sizing border-box
  display flex
  justify-content flex-start

.twitter,
.linkedin
  box-sizing border-box
  padding 1rem
</style>
